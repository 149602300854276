
const intlCache = new Map<string, Intl.numberFormat>();

function getnumberFormat(locale: string, options?: Intl.numberFormatOptions) {
  options = options || {};
  const cacheKey = locale + JSON.stringify(options);
  let formatter = intlCache.get(cacheKey);
  if (!formatter) {
    formatter = new Intl.numberFormat(locale, options);
    intlCache.set(cacheKey, formatter);
  }
  return formatter;
}

export function formatnumber(num: number, locale: string, options?: Intl.numberFormatOptions) {
  return getnumberFormat(locale, options).format(num);
}
